import { CONFIGURACION_PRODUCTOS, CONFIGURACION_SERVICIOS, CONFIGURACION_REGIMENES_FISCALES, CONFIGURACION_CATEGORIAS, CONFIGURACION_SUBCATEGORIAS, CONFIGURACION_AREAS, CONFIGURACION_UBICACIONES } from '../actions/types';

const initialState = {
    productos: {
        listado: [],
        detalles: null
    }, 
    servicios: {
        listado: [],
        detalles: null
    },
    regimenes_fiscales: {
        listado: [],
        detalles: null
    }, 
    categorias: {
        listado: [],
        detalles: null
    },
    subcategorias: {
        listado: [],
        detalles: null
    },
    areas: {
        listado: [],
        detalles: null
    }, 
    ubicaciones: {
        listado: [],
        detalles: null
    }
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case CONFIGURACION_PRODUCTOS.BUSCAR:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    listado: payload
                }
            };

        case CONFIGURACION_PRODUCTOS.AGREGAR:
            let productos_agregar = state.productos.listado;

            productos_agregar = [
                ...productos_agregar, payload
            ];

            productos_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: productos_agregar
                }
            };

        case CONFIGURACION_PRODUCTOS.DETALLES:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    detalles: payload
                }
            };

        case CONFIGURACION_PRODUCTOS.EDITAR:
            let productos_editar = state.productos.listado;

            productos_editar = productos_editar.map(producto => producto._id === payload._id ? payload : producto);

            productos_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: productos_editar
                }
            };

        case CONFIGURACION_PRODUCTOS.ELIMINAR:
            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: state.productos.listado.filter( producto => producto._id !== payload.id )
                }
            };
    
        case CONFIGURACION_SERVICIOS.BUSCAR:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    listado: payload
                }
            };

        case CONFIGURACION_SERVICIOS.AGREGAR:
            let servicios_agregar = state.servicios.listado;

            servicios_agregar = [
                ...servicios_agregar, payload
            ];

            servicios_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: servicios_agregar
                }
            };

        case CONFIGURACION_SERVICIOS.DETALLES:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    detalles: payload
                }
            };

        case CONFIGURACION_SERVICIOS.EDITAR:
            let servicios_editar = state.servicios.listado;

            servicios_editar = servicios_editar.map(servicio => servicio._id === payload._id ? payload : servicio);

            servicios_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: servicios_editar
                }
            };

        case CONFIGURACION_SERVICIOS.ELIMINAR:
            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: state.servicios.listado.filter( servicio => servicio._id !== payload.id )
                }
            };      

        case CONFIGURACION_REGIMENES_FISCALES.BUSCAR:
            return {
                ...state,
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: payload
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.AGREGAR:
            let regimenes_agregar = state.regimenes_fiscales.listado;

            regimenes_agregar = [
                ...regimenes_agregar, payload
            ];

            regimenes_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: regimenes_agregar
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.DETALLES:
            return {
                ...state,
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    detalles: payload
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.EDITAR:
            let regimenes_editar = state.regimenes_fiscales.listado;

            regimenes_editar = regimenes_editar.map(regimen => regimen._id === payload._id ? payload : regimen);

            regimenes_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: regimenes_editar
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.ELIMINAR:
            return {
                ...state, 
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: state.regimenes_fiscales.listado.filter( regimen => regimen._id !== payload.id )
                }
            };

        case CONFIGURACION_CATEGORIAS.BUSCAR:
            return {
                ...state,
                categorias: {
                    ...state.categorias, 
                    listado: payload
                }
            };
        
        case CONFIGURACION_CATEGORIAS.AGREGAR:
            let categorias_agregar = state.categorias.listado;
        
            categorias_agregar = [
                ...categorias_agregar, payload
            ];
        
            categorias_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                categorias: {
                    ...state.categorias, 
                    listado: categorias_agregar
                }
            };
        
        case CONFIGURACION_CATEGORIAS.DETALLES:
            return {
                ...state,
                categorias: {
                    ...state.categorias, 
                    detalles: payload
                }
            };
        
        case CONFIGURACION_CATEGORIAS.EDITAR:
            let categorias_editar = state.categorias.listado;
        
            categorias_editar = categorias_editar.map(categoria => categoria.id === payload.id ? payload : categoria);
        
            categorias_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                categorias: {
                    ...state.categorias, 
                    listado: categorias_editar
                }
            };
        
        case CONFIGURACION_CATEGORIAS.ELIMINAR:
            return {
                ...state, 
                categorias: {
                    ...state.categorias, 
                    listado: state.categorias.listado.filter( categoria => categoria.id !== payload.id )
                }
            };

        case CONFIGURACION_SUBCATEGORIAS.BUSCAR:
            return {
                ...state,
                subcategorias: {
                    ...state.subcategorias, 
                    listado: payload
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.AGREGAR:
            let subcategorias_agregar = state.subcategorias.listado;
        
            subcategorias_agregar = [
                ...subcategorias_agregar, payload
            ];
        
            subcategorias_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                subcategorias: {
                    ...state.subcategorias, 
                    listado: subcategorias_agregar
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.DETALLES:
            return {
                ...state,
                subcategorias: {
                    ...state.subcategorias, 
                    detalles: payload
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.EDITAR:
            let subcategorias_editar = state.subcategorias.listado;
        
            subcategorias_editar = subcategorias_editar.map(subcategoria => subcategoria.id === payload.id ? payload : subcategoria);
        
            subcategorias_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                subcategorias: {
                    ...state.subcategorias, 
                    listado: subcategorias_editar
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.ELIMINAR:
            return {
                ...state, 
                subcategorias: {
                    ...state.subcategorias, 
                    listado: state.subcategorias.listado.filter( subcategoria => subcategoria.id !== payload.id )
                }
            };

        case CONFIGURACION_AREAS.BUSCAR:
            return {
                ...state,
                areas: {
                    ...state.areas, 
                    listado: payload
                }
            };

        case CONFIGURACION_AREAS.AGREGAR:
            let areas_agregar = state.areas.listado;

            areas_agregar = [
                ...areas_agregar, payload
            ];

            areas_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                areas: {
                    ...state.areas, 
                    listado: areas_agregar
                }
            };

        case CONFIGURACION_AREAS.DETALLES:
            return {
                ...state,
                areas: {
                    ...state.areas, 
                    detalles: payload
                }
            };

        case CONFIGURACION_AREAS.EDITAR:
            let areas_editar = state.areas.listado;

            areas_editar = areas_editar.map(area => area.id === payload.id ? payload : area);

            areas_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                areas: {
                    ...state.areas, 
                    listado: areas_editar
                }
            };

        case CONFIGURACION_AREAS.ELIMINAR:
            return {
                ...state, 
                areas: {
                    ...state.areas, 
                    listado: state.areas.listado.filter( area => area.id !== payload.id )
                }
            };
        
        case CONFIGURACION_UBICACIONES.BUSCAR:
            return {
                ...state,
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: payload
                }
            };

        case CONFIGURACION_UBICACIONES.AGREGAR:
            let ubicaciones_agregar = state.ubicaciones.listado;

            ubicaciones_agregar = [
                ...ubicaciones_agregar, payload
            ];

            ubicaciones_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: ubicaciones_agregar
                }
            };

        case CONFIGURACION_UBICACIONES.DETALLES:
            return {
                ...state,
                ubicaciones: {
                    ...state.ubicaciones, 
                    detalles: payload
                }
            };

        case CONFIGURACION_UBICACIONES.EDITAR:
            let ubicaciones_editar = state.ubicaciones.listado;

            ubicaciones_editar = ubicaciones_editar.map(ubicacion => ubicacion.id === payload.id ? payload : ubicacion);

            ubicaciones_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: ubicaciones_editar
                }
            };

        case CONFIGURACION_UBICACIONES.ELIMINAR:
            return {
                ...state, 
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: state.ubicaciones.listado.filter( ubicacion => ubicacion.id !== payload.id )
                }
            };
            
        default:
            return state;
    }
}