import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../../actions/breadcrumb';
import { obtenerAreaConfiguracion, buscarUbicacionesConfiguracion } from '../../../../actions/configuracion';

import NuevaUbicacion from './NuevaUbicacion';
import EditarUbicacion from './EditarUbicacion';
import EliminarUbicacion from './EliminarUbicacion';

const Ubicaciones = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Áreas',
            path: '/configuracion/areas'
        },
        {
            activo: true,
            nombre: 'Ubicaciones',
            path: '/configuracion/areas/ubicaciones/'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.ubicaciones);
    const { detalles } = useSelector(state => state.configuracion.areas);

    const { id } = useParams();

    const [ubicaciones, setUbicaciones] = useState([]);

    const [ubicacionAgregar, setUbicacionAgregar] = useState(null);
    const [ubicacionEditar, setUbicacionEditar] = useState(null);
    const [ubicacionEliminar, setUbicacionEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Productos existentes',
            selector: row => row.productos,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarUbicacion(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarUbicacion(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Crear ubicacion */
    const handleAgregarUbicacion = (ubicacion) => {
        setUbicacionEditar(null);
        setUbicacionEliminar(null);

        setUbicacionAgregar(ubicacion);        
    }

    /* Editar ubicacion */
    const handleEditarUbicacion = (ubicacion) => {
        setUbicacionAgregar(null);
        setUbicacionEliminar(null);

        setUbicacionEditar(ubicacion);
    }

    /* Eliminar ubicacion */
    const handleEliminarUbicacion = (ubicacion) => {
        setUbicacionAgregar(null);
        setUbicacionEditar(null);

        setUbicacionEliminar(ubicacion);
    }

    useEffect(() => {
        setUbicaciones(listado);
    }, [listado]);

    useEffect(() => {
        if(id){
            dispatch(obtenerAreaConfiguracion(id));
            dispatch(buscarUbicacionesConfiguracion(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    return (
        <Fragment>

            <NuevaUbicacion ubicacion={ubicacionAgregar} reestablecerUbicacion={handleAgregarUbicacion} />
            <EditarUbicacion ubicacion={ubicacionEditar} reestablecerUbicacion={handleEditarUbicacion} />
            <EliminarUbicacion ubicacion={ubicacionEliminar} reestablecerUbicacion={handleEliminarUbicacion} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Ubicaciones {detalles?.nombre ? ' de ' + detalles.nombre : ''}</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarUbicacion({ id_area: id })}>Agregar ubicación</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={ubicaciones}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Ubicaciones;